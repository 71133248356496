<template>
<div>
  <div class="head-div">
    <el-steps :active="active" finish-status="success">
      <el-step title="巡检" />
      <el-step title="提交复核" />
      <el-step title="完成" />
    </el-steps>
  </div>
  <div class="body-div">
    <!-- 左侧监控画面 -->
    <div class="video-div" v-if="active != 3">
      <!-- 视频插件组件 -->
      <VideoPluginView
        ref="video_perview_ref"
        class="video-plugin-view"
      ></VideoPluginView>
    </div>
    <!-- 右侧巡检页面 -->
    <div class="check-div" v-if="active != 3">
      <div class="check-info">
        <div class="info-div" v-for="(i, index) in table.list">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="i.group_name + i.equipment_name"
            placement="top"
            :disabled="i.group_name.length + i.equipment_name.length <= 16"
          >
            <el-text class="w-218px" truncated>{{ i.group_name }}{{ i.equipment_name }}</el-text>
          </el-tooltip>
          <span>{{ i.hik_no }}</span>
          <el-icon class="reFresh-icon" @click="reFreshVideo(i, index)"><RefreshRight /></el-icon>
          <p>{{ i.capture_time }}</p>
          <el-checkbox-group v-model="i.statusNameArr" @change="checkChange(i)">
            <el-checkbox
              v-for="item in table.statusList"
              :key="item.status_id"
              :label="item.status_name"
              :min="1"
            />
            <el-popover placement="top" :width="200" trigger="click">
              <template #reference>
                <el-icon class="check-icon" size="30px"><Edit/></el-icon>
              </template>
              <el-input
                v-model="i.remark"
                type="textarea"
                placeholder="请输入问题具体描述"
                maxlength="50"
                show-word-limit
              />
            </el-popover>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <!-- 完成页面 -->
    <div class="done-div" v-if="active === 3">
      <el-text class="mx-1" size="large">今日巡检已完成</el-text>
    </div>
  </div>
  <div class="foot-div">
    <div class="text-div">
      <span class="text-word">待巡检</span>
      <span class="text-number text-before">{{ table.beforeCheck }}</span>
      <span class="text-word">已巡检</span>
      <span class="text-number text-after">{{ table.afterCheck }}</span>
      <span class="text-word">复核设备</span>
      <span class="text-number text-re">{{ table.reCheck }}</span>
    </div>
    <el-button type="primary" size="large" plain @click="nextBatch" v-if="active != 3">{{active === 2 && table.reCheck <= 9 ? '确认提交' : '下一批'}}</el-button>
  </div>
</div>
</template>

<script setup>
import {
  getCurrentInstance,
  ref,
  onMounted,
  onBeforeUpdate,
  onBeforeUnmount,
  reactive,
  computed,
  nextTick,
  watch,
} from "vue";
import VideoPluginView from "@/components/common/VideoPlugin.vue";

// 视频预览挂载
const video_perview_ref = ref(null);

// 刷新重新加载指定视频
const reFreshVideo = (e, index) => {
  ctx.$message({
    message: '如果提示"该点位已在播放，请勿重复打开"，请先在对应视频处右上角点击关闭后再刷新',
    type: 'warning',
  })
  video_perview_ref.value.reFreshPage({
    device_sn: e.hik_no,
    channel_no: e.channel_no,
    device_name: e.equipment_name,
    group_name: e.group_name
  }, index+1);
}

// 打开视频预览界面
const openChannelsPerviewPage = (cs_id_arr) => {
  let videoIdArr = [];
  cs_id_arr.forEach((e) => {
    videoIdArr.push({
      group_name: e.group_name,
      device_name: e.equipment_name,
      device_sn: e.hik_no,
      channel_no: e.channel_no,
    })
  });
  video_perview_ref.value.openPage(videoIdArr, table.ezviz_account);
};

// 访问 globalProperties
const ctx = getCurrentInstance().appContext.config.globalProperties;

const active = computed(() => {
  if (table.beforeCheck != 0) {
    return 1
  } else if (table.beforeCheck === 0 && table.reCheck != 0) {
    return 2
  } else if (table.beforeCheck === 0 && table.reCheck === 0) {
    return 3
  } else {
    return 0
  }
})

// 设置多选框触发
const checkChange = (item) => {
  let statusArr = []
  let statusNum = 0
  item.statusNameArr.forEach((element) => {
    table.statusList.forEach((e) => {
      if (element === e.status_name) {
        statusArr.push(e.status_id)
        statusNum = e.status_id
      }
    })
  })
  item.statusIdArr = statusArr

  if (statusNum == 1) {
    item.statusIdArr = [1]
    item.statusNameArr = ['正常']
  } else {
    item.statusIdArr = item.statusIdArr.filter(i => i !== 1)
    item.statusNameArr = item.statusNameArr.filter(i => i !== '正常')
  }
};

// 列表信息
const table = reactive({
  list: [], // 数据列表
  statusList: [], // 状态列表
  conf: {
    loadStatus: false,
    curPage: 1, // 不用变
    pageLimit: 9, // 不用变
    dataTotal: 0,
    emptyText: "",
  },
  ezviz_account: {},
  beforeCheck: 0, // 待巡检
  afterCheck: 0, // 已巡检
  reCheck: 0, // 复核设备
});

onMounted(() => {
  statusSet();
  loadCount();
});

// 获取table列表
const loadList = () => {
  // 清除现有table数据
  table.list = [];
  table.conf.loadStatus = true;
  table.conf.emptyText = "数据查询中...";
  let dataObj = {
    page: table.conf.curPage,
    limit: table.conf.pageLimit
  }
  // 是否是提交复核阶段
  if (active.value === 2) {
    dataObj.type = "review"
  }
  // 日常巡检信息
  ctx.$request_nl_
    .post(ctx.$api_.state.Inspection.inspection_daily.list.url, dataObj)
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        table.conf.dataTotal = respon.Data.data.total;
        table.list = respon.Data.data.data;
        // 给获取到的列表添加状态默认值
        if (active.value === 1) {
          table.list.forEach((e) => {
            e.statusIdArr = [1]
            e.statusNameArr = ['正常']
          })
        } else {
          table.list.forEach((e) => {
            e.statusIdArr = [...e.status_id]
            e.statusNameArr = convertState(e.statusIdArr)
          })
        }
        table.ezviz_account = respon.Data.ezviz_account;
        openChannelsPerviewPage(table.list);
      } else {
        ctx.$message.error({
          message: respon.Message,
        });
      }
      table.conf.loadStatus = false;
      table.conf.emptyText = respon.Data.data.length > 0 ? respon.Message : "暂无更多数据";
    })
    .catch((error) => {
      table.conf.loadStatus = false;
      table.conf.emptyText = "服务器连接失败，请稍后重试";
    });
};

// 传入状态id数组转换成文字数组
const convertState = (idArr) => {
  let textArr = [];
  for (let index = 0; index < idArr.length; index++) {
    for (let i = 0; i < table.statusList.length; i++) {
      parseInt(table.statusList[i].status_id) === parseInt(idArr[index]) ? textArr.push(table.statusList[i].status_name) : ''
    }
  }
  return textArr
}

// 日常巡检获取统计
const loadCount = () => {
  ctx.$request_nl_
    .post(ctx.$api_.state.Inspection.inspection_daily.count.url, {})
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        table.beforeCheck = respon.Data.need_num;
        table.afterCheck = respon.Data.had_num;
        table.reCheck = respon.Data.review_num;
        loadList();
      } else {
        ctx.$message.error({
          message: respon.Message,
        });
      }
    })
    .catch((error) => {
      ctx.$message.error({
          message: 获取统计请求出错了,
        });
    });
};

// 添加巡检记录
const addRecord = () => {
  let dataArr = [];
  for (let index = 0; index < table.list.length; index++) {
    const element = {
      "equipment_id": table.list[index].equipment_id,
      "status_id": table.list[index].statusIdArr,
  		"remark": table.list[index].remark || ''
    };
    dataArr.push(element)
  }
  ctx.$request_nl_.post(ctx.$api_.state.Inspection.inspection_daily.add.url, {data: dataArr})
    .then((respon) => {
  	if (respon.Code === ctx.$code_.state.success) {
      loadCount();
  	} else {
  		ctx.$message.error({
  			message: respon.Message,
  		});
  	}
  }).catch((error) => {
    ctx.$message.error({
      message: error,
    });
  });
};

// 编辑巡检记录
const editRecord = () => {
  let dataArr = []
  for (let index = 0; index < table.list.length; index++) {
    const element = {
      "inspection_record_id": table.list[index].inspection_record_id,
      "equipment_id": table.list[index].equipment_id,
      "status_id": table.list[index].statusIdArr,
  		"remark": table.list[index].remark || ''
    };
    dataArr.push(element)
  }
  ctx.$request_nl_
    .post(ctx.$api_.state.Inspection.inspection_daily.edit.url, {data: dataArr})
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        loadCount();
      } else {
        ctx.$message.error({
          message: respon.Message,
        });
      }
    })
    .catch((error) => {
      ctx.$message.error({
          message: respon.Message,
        });
    });
};

// 巡检状态设置
const statusSet = () => {
  ctx.$request_nl_
    .post(ctx.$api_.state.Inspection.inspection_daily.status.url, {})
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        table.statusList = respon.Data;
      } else {
        ctx.$message.error({
          message: respon.Message,
        });
      }
    })
    .catch((error) => {
      ctx.$message.error({
          message: 巡检状态设置请求出错,
        });
    });
};

// 下一批
const nextBatch = async () => {
  // 销毁之前一个视频插件组件
  video_perview_ref.value.closeWebControl();
  if (active.value === 1) {
    await addRecord();
  } else if (active.value === 2) {
    await editRecord();
  }
};
</script>

<style scoped lang="less">
.body-div {
  min-width: 568px;
  display: flex;
  flex-wrap: wrap;
  .video-div {
    position: relative;
    width: 60%;
    height: 620px;
    .video-plugin-view {
      width: 100%;
      height: 100%;
    }
  }
  .check-div {
    width: 40%;
    .check-info {
      width: 100%;
      height: 100%;
      min-width: 219px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      .info-div {
        width: 33%;
        min-height: 207px;
        position: relative;
        border: 1px solid white;
        background-color: #d0d0d0;
        padding: 1px;
        box-sizing: border-box;
        margin: 0;
        .w-218px {
          display: block;
        }
        .el-checkbox {
          margin-right: 9px;
        }
        .reFresh-icon {
          float: right;
          width: 21px;
          height: 21px;
          &:hover {
            cursor: pointer;
          }
        }
        .check-icon {
          position: absolute;
          bottom: 0;
          right: 0;
          display: block;
          &:hover {
            cursor: pointer;
          }
        }
        .el-radio {
          margin-right: 15px;
        }
      }
    }
  }
  .done-div {
    width: 100%;
    height: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.foot-div {
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .text-div {
    position: absolute;
    left: 0;
    top: 0;
    .text-word {
      display: inline-block;
      color: #d0d0d0;
    }
    .text-number {
      font-weight: bold;
      font-size: 16px;
      margin-right: 10px;
    }
    .text-before {
      color: #29a4ff;
    }
    .text-after {
      color: #1e1e1e;
    }
    .text-re {
      color: #ffc634;
    }
  }
}
</style>
