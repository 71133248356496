<template>
  <div id="channels_preview">
    <div id="playWnd" class="preview-play"></div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  reactive,
  defineExpose,
  onBeforeUnmount,
} from "vue";

// 访问 globalProperties
const ctx = getCurrentInstance().appContext.config.globalProperties;

onBeforeUnmount(() => {
  setTimeout(() => {
    closeWebControl();
  }, 100);
});

// 视频配置参数
const previewConf = reactive({
  webControl: null,
  webSzPluginContainer: "playWnd",
  arr: [],
  // group_name: "",
  // device_name: "",
  // device_sn: "",
  // channel_no: "",
  ezviz_account: {},
});

const openPage = (cs_id_arr, ezAccount) => {
  previewConf.ezviz_account = ezAccount;
  previewConf.arr = cs_id_arr;
  // for (let index = 0; index < cs_id_arr.length; index++) {
  //   const element = cs_id_arr[index];
  //   await previewVideoConfiguration(element);
  // }
  WebControlInit();
};

const reFreshPage = (e, index) => {
  startPreview(e, index)
}

// 查询预览视频配置参数信息
const previewVideoConfiguration = async (cs_id) => {
  await ctx.$request_
    .post(ctx.$api_.state.Carema.channels.preview.url, {
      cs_id: cs_id,
    })
    .then((respon) => {
      if (respon.Code === ctx.$code_.state.success) {
        let data = {};
        data = {
          group_name: respon?.Data?.group_name,
          device_name: respon?.Data?.device_name,
          device_sn: respon?.Data?.device_sn, // 两个相同的sn后一个不会出现，两个空值也不行
          channel_no: respon?.Data?.channel_no,
        };
        previewConf.arr.push(data);
        // previewConf.group_name = respon.Data.group_name;
        // previewConf.device_name = respon.Data.device_name;
        // previewConf.device_sn = respon.Data.device_sn;
        // previewConf.channel_no = respon.Data.channel_no;
        // previewConf.ezviz_account = respon.Data.ezviz_account;
      } else {
        previewConf.arr.push({
          group_name: '',
          device_name: '',
          device_sn: '',
          channel_no: '',
          // ezviz_account: {
          //   appKey: "a7cb8b1d58ae42cc9b841fe13d9618e5",
          //   token:"ra.47kwdcfraunceyqi54ocjo3lc03dl16z-3rasepf6d0-1v706n5-ux08afzgn"
          // }
        })
        ctx.$notify({
          title: "温馨提示",
          message: respon.Message,
          type: "warning",
        });
      }
    });
};

// 建立插件实例
const WebControlInit = () => {
  previewConf.webControl = new WebControl({
    szPluginContainer: previewConf.webSzPluginContainer,
    iServicePortStart: 14510,
    iServicePortEnd: 14519,
    cbConnectSuccess: () => {
      previewConf.webControl
        .JS_StartService("window", {
          dllPath: "./chain/cloudTransform.dll",
        })
        .then(() => {
          previewConf.webControl
            .JS_CreateWnd(previewConf.webSzPluginContainer, 980, 610)
            .then(() => {});
        })
        .catch((error) => {
          previewConf.webControl = null;
          ctx.$notify({
            title: "温馨提示",
            message: "插件启动失败，请检查是否安装插件！",
            type: "error",
          });
        });
    },
    cbConnectError: () => {
      previewConf.webControl = null;
      ctx.$notify({
        title: "温馨提示",
        message: "插件启动失败，请检查是否安装插件！",
        type: "error",
      });
    },
    cbConnectClose: (bNormalClose) => {},
  });

  // 设置插件位置
  previewConf.webControl.oDocOffset.top = 5;
  previewConf.webControl.oDocOffset.left = 10;

  setTimeout(() => {
    // 初始化插件
    previewConf.webControl
      .JS_RequestInterface({
        funcName: "Init",
        arguments: encodeURI(
          JSON.stringify({
            layout: 6,
            iWndType: 0,
            response: {
              code: 0,
              message: null,
              data: {
                appKey: previewConf.ezviz_account.appKey,
                ezvizToken: previewConf.ezviz_account.token,
                videoLevel: 1,
                showMainTool: 0,
                showSubTool: 1,
                waterMark: ctx.$system_.state.login_user_name,
                userName: ctx.$system_.state.login_user_name,
              },
            },
          })
        ),
      })
      .then(function () {
        // 开启预览
        for (let index = 0; index < previewConf.arr.length; index++) {
          const element = previewConf.arr[index];
          startPreview(element);
        }
      });
  }, 1500);
};

// 开启预览
const startPreview = async (e, index) => {
  let data = {
    deviceSerial: e.device_sn || 0,
    channelNo: parseInt(e.channel_no) || 0,
    codeIsEncrypt: 0,
    channelName: e.device_name || 0,
    storeName: e.group_name || 0,
  }
  if (index) {
    data.playWndIndex = index
  }
  await previewConf.webControl
    .JS_RequestInterface({
      funcName: "StartPreview",
      arguments: encodeURI(
        JSON.stringify({
          response: {
            code: 0,
            message: null,
            data: data,
          },
        })
      ),
    })
    .then(function () {});
};

const closeWebControl = () => {
  previewConf.webControl.JS_HideWnd();
  previewConf.webControl.JS_DestroyWnd();
};

defineExpose({
  openPage,
  closeWebControl,
  reFreshPage
});
</script>
<style lang="less">
#channels_preview {
  width: 100%;
  height: 100%;
  #playWnd {
    height: 100%;
    width: 100%;
  }
}
</style>
